<template>
  <div class="row">
    <div class="flex xs12 md6">
      <vac-card class="trends-info">
        <p class="no-wrap trends-info-header">{{ $t('Impressions') }}</p>
        <div class="row trends-info__row">
          <div class="trends-info__row-content" v-for="(item, key) in data[0].brands" :key="key">
            <p class="trends-info__row-content__value">{{ (item.value).toLocaleString("ru") }}</p>
            <p class="trends-info__row-content__label">{{ item.label }}</p>
          </div>
        </div>
        <div
          class="trends-info__tile">
          <vac-card :class='["mb-4"]' style="display: flex;" class="bgc-grey">
            <div style="width: fit-content;">
              <p :style="{'color': 'black'}" class="display-2 mb-1">{{ (data[0].total).toLocaleString("ru") }}</p>
              <p :style="{'color': 'black'}" class="subtext">Total</p>
            </div>
          </vac-card>
        </div>
        <div
          class="trends-info__tile">
          <vac-card :class='["mb-4"]' style="display: flex;" class="bgc-green">
            <div style="width: fit-content;">
              <p :style="{'color': 'white'}" class="display-2 mb-1">{{ data[0].share }}</p>
              <p :style="{'color': 'white'}" class="subtext">Share</p>
            </div>
          </vac-card>
        </div>
      </vac-card>
    </div>
    <div class="flex xs12 md6">
      <vac-card class="trends-info">
        <p class="no-wrap trends-info-header">{{ $t('Users') }}</p>
        <div class="row trends-info__row">
          <div class="trends-info__row-content" v-for="(item, key) in data[1].brands" :key="key">
            <p class="trends-info__row-content__value">{{ (item.value).toLocaleString("ru") }}</p>
            <p class="trends-info__row-content__label">{{ item.label }}</p>
          </div>
        </div>
        <div
          class="trends-info__tile">
          <vac-card :class='["mb-4"]' style="display: flex;" class="bgc-grey">
            <div style="width: fit-content;">
              <p :style="{'color': 'black'}" class="display-2 mb-1">{{ (data[1].total).toLocaleString("ru") }}</p>
              <p :style="{'color': 'black'}" class="subtext">Total</p>
            </div>
          </vac-card>
        </div>
        <div
          class="trends-info__tile">
          <vac-card :class='["mb-4"]' class="bgc-red" style="display: flex;">
            <div style="width: fit-content;">
              <p :style="{'color': 'white'}" class="display-2 mb-1">{{ data[1].share }}</p>
              <p :style="{'color': 'white'}" class="subtext">Share</p>
            </div>
          </vac-card>
        </div>
      </vac-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrendsInfoTiles",
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.bgc-green {
  background: linear-gradient(90deg, #65e5b4 -0.36%, #29e069 99.64%);
}

.bgc-red {
  background: linear-gradient(90deg, #ff7657 -0.36%, #e34a4a 99.64%);
}

.bgc-grey {
  background: rgba(196, 208, 218, 1);
}

.trends-info {
  &-header {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  &__row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 24px 0 0 !important;
    flex-wrap: wrap;

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 33%;
      margin-bottom: 24px;
      padding-left: 20px;

      &__value {
        font-style: normal;
        font-weight: 550;
        font-size: 32px;
        line-height: 32px;
        color: #000000;
        margin-bottom: 4px;
      }

      &__label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #8d9ca8;
      }
    }
  }
}
</style>
